<template>
  <div class="setting" >
    <div class="head" @touchmove.prevent>
      <img
        src="../../assets/img/commoditySpike/back.png"
        alt="返回"
        @click="$router.back()"
      />
      <div class="title">设置</div>
    </div>
    
    <div class="main">
      <ul>
        <li>
          <div class="left">
            <img src="../../assets/img/touxiang.png" alt="" />
          </div>
          <div class="right">
            修改头像
            <img
              src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4db3f6ebbb11bca02917aa17fa658726db449c768b690480fddf47cb37455581"
              alt=""
            />
          </div>
        </li>
        <li>
          <div class="left">昵称</div>
          <div class="right">
            飞的更高<img
              src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4db3f6ebbb11bca02917aa17fa658726db449c768b690480fddf47cb37455581"
              alt=""
            />
          </div>
        </li>
        <li>
          <div class="left">个性签名</div>
          <div class="right">
            未填写<img
              src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4db3f6ebbb11bca02917aa17fa658726db449c768b690480fddf47cb37455581"
              alt=""
            />
          </div>
        </li>
      </ul>
      <ul>
        <li>
          <div class="left">修改密码</div>
          <div class="right">
            <img
              src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4db3f6ebbb11bca02917aa17fa658726db449c768b690480fddf47cb37455581"
              alt=""
            />
          </div>
        </li>
        <li>
          <div class="left">修改手机号</div>
          <div class="right">
            12345678900<img
              src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4db3f6ebbb11bca02917aa17fa658726db449c768b690480fddf47cb37455581"
              alt=""
            />
          </div>
        </li>
        <li>
          <div class="left">添加生日</div>
          <div class="right">
            去添加<img
              src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4db3f6ebbb11bca02917aa17fa658726db449c768b690480fddf47cb37455581"
              alt=""
            />
          </div>
        </li>
        <li>
          <div class="left">绑定微信号</div>
          <div class="right">
            去添加<img
              src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4db3f6ebbb11bca02917aa17fa658726db449c768b690480fddf47cb37455581"
              alt=""
            />
          </div>
        </li>
        <li>
          <div class="left">绑定支付宝</div>
          <div class="right">
            去添加<img
              src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4db3f6ebbb11bca02917aa17fa658726db449c768b690480fddf47cb37455581"
              alt=""
            />
          </div>
        </li>
        <li>
          <div class="left">授权管理</div>
          <div class="right">
            <img
              src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4db3f6ebbb11bca02917aa17fa658726db449c768b690480fddf47cb37455581"
              alt=""
            />
          </div>
        </li>
        <li>
          <div class="left">注销账号</div>
          <div class="right" style="color: #ff7200">
            请谨慎操作<img
              src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4db3f6ebbb11bca02917aa17fa658726db449c768b690480fddf47cb37455581"
              alt=""
            />
          </div>
        </li>
        <li>
          <div class="left">意见反馈</div>
          <div class="right">
            <img
              src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4db3f6ebbb11bca02917aa17fa658726db449c768b690480fddf47cb37455581"
              alt=""
            />
          </div>
        </li>
      </ul>
      <ul>
        <li>
          <div class="left">
           邀请码
          </div>
          <div class="right">
            未填写
            <img
              src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4db3f6ebbb11bca02917aa17fa658726db449c768b690480fddf47cb37455581"
              alt=""
            />
          </div>
        </li>
        <li>
          <div class="left">清除缓存</div>
          <div class="right">
            500M<img
              src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4db3f6ebbb11bca02917aa17fa658726db449c768b690480fddf47cb37455581"
              alt=""
            />
          </div>
        </li>
        <li>
          <div class="left">关于</div>
          <div class="right">
           当前版本1.0.1<img
              src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4db3f6ebbb11bca02917aa17fa658726db449c768b690480fddf47cb37455581"
              alt=""
            />
          </div>
        </li>
      </ul>
    </div>
    <div class="footer" @touchmove.prevent>
        <div class="out">退出</div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    setRem() {
      let screenWidth = document.documentElement.getBoundingClientRect().width;
      document.documentElement.style.fontSize = screenWidth / 10 + "px";
    },
  },
  mounted() {
    this.setRem();
    window.addEventListener("orientationchange", this.setRem);
    window.addEventListener("resize", this.setRem);
  },
  beforeDestroy() {
    document.documentElement.style.fontSize = "16px";
  },
};
</script>

<style lang="less" scoped>
.setting {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: PingFangSC-Semibold, PingFang SC;
  background-color: white;
  .head {
    display: flex;
    padding: 0.266667rem 0.933333rem 0.266667rem 0.266667rem;
    align-items: center;
    img {
      width: 0.666667rem;
      height: 0.64rem;
    }
    .title {
      flex: 1;
      text-align: center;
      font-size: 0.453333rem;
      font-weight: 600;
    }
  }
  .main::-webkit-scrollbar{
      display: none;
  }
  .main {
    flex: 1;
    overflow: auto;
    padding: 0 0.426667rem;
    ul {
        margin-bottom: .266667rem;
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 1.493333rem;
        .left {
          font-size: 0.453333rem;
          color: #333;
          img {
            width: 1.066667rem;
            height: 1.066667rem;
            border-radius: 0.426667rem;
          }
        }
        .right {
          display: flex;
          align-items: center;
          img {
            width: 0.186667rem;
            height: 0.346667rem;
            margin-left: 0.266667rem;
          }
          color: #ccc;
          font-size: 0.453333rem;
          
        }
      }
    }
  }
  .footer{
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
        padding:  .566667rem .426667rem;
        .out{
            background-image: linear-gradient(to right, #FF7200,#FF3C00);
            color: white;
            font-size: .373333rem;
            height: .96rem;
            border-radius: .48rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
  }
}
</style>